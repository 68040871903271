.mainCardDiv {
    padding: 1.3rem;
    margin: 10px 6px;
    flex: 1;
    height: 18rem;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: .2s ease-in;
}

.mainCardDiv:hover {
    transform: scale(1.04);
    transition: .2s ease-in;
}

.upperDiv {
    height: 80%;
    display: flex;
    margin-bottom: 8%;
    align-items: center;
}

.imgDiv {
    width: 35%;
    overflow: hidden;
    padding-right: 10%;
}

.cardImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.heading {
    font-family: Antonio;
    color: #163653;
    font-size: 1.2rem;
    font-weight: 900;
    text-transform: uppercase;
}

.contentDiv {
    height: 80% !important;
    overflow:hidden;
    /* background-color: red; */
}

.contentText {
    font-family: Poppins;
    font-weight: 600;
    font-size: .9rem;
    color: #163653;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    line-height: 1;
    max-height: 7;

}