.blueContainer {
  /* background: #163253; */
  background-color: #7abaf7;
  color: #163253;
  text-align: center;
  padding: 0.4rem;
  margin: 1rem;
  display: block;
  border-radius: 5px;
  transition: 2s;
}

.text {
  padding: 0;
  margin: 0;
  font-weight: bold;
  /* letter-spacing: 0.08rem; */
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  text-transform: uppercase;
}
