.mainHomeDiv {
  width: 100%;
  /* height: 100vh; */
  max-height: 100%;
  min-height: 700px;
  background-color: #7abaf7;
  position: relative;
  margin-bottom: 220px;
  margin-bottom: 25%;
}
.typingWrapper{
  min-height: 20rem !important;
}
.rightDiv {
  /* background-color: red; */
  position: absolute;
  right: 0;
  top: 0;
  height: 90%;
  width: 50%;
  overflow: hidden;
  padding-bottom: 10px;
  /* overflow: hidden; */
}

.Substract {
  position: absolute;
  height: 50%;
  /* background-color: red; */
  z-index: 12;
  right: 190px;
  bottom: -15px;
}

.mainImg {
  /* background-color: red; */
  position: absolute;
  top: 0rem;
  max-width: 100%;
  right: -10rem;
  height: 100%;
  z-index: 10;
}

.rightBackCircle {
  height: 100%;
  max-width: 100%;
  position: absolute;
  right: -30px;
  top: -14%;
  overflow: hidden;
}

.leftDiv {
  position: absolute;
  bottom: -50%;
  left: 0;
  width: 30%;
  height: 100%;
  z-index: 0;
}

.sideCircle {
  position: absolute;
  top: -6%;
  width: 100%;
}

.leftDivLinks {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 90px;
  padding-bottom: 30px;
  height: 100%;
  width: 6%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.leftDivLinksSec1 {
  transform: rotate(270deg);
  display: flex;
  list-style: none;
  justify-content: space-around;
}

.leftDivLinksSec2 {
  transform: rotate(0deg);
  display: flex;
  list-style: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 45%;
}

.leftDivLinksSec2 .linkItem {
  padding: 10px 10px;
  height: 10px;
  display: flex;
  min-height: 80px;
  font-family: "Antonio", sans-serif;
  font-size: 1.1em;
  font-weight: 900;
  color: #163653;
  display: flex;
  justify-content: center;
  padding-right: 20px;
}

.leftDivLinksSec2 .linkItem>a>i {
  font-size: 1.8rem;
}

.leftDivLinksSec2 .linkItem>a:hover {
  color: #163653;
}

.linkItem {
  padding: 10px 10px;
  height: 10px;
  display: flex;
  font-size: 1em;
  min-width: 80px;
  font-family: "Antonio", sans-serif;
  font-size: 1.1em;
  font-weight: 900;
  color: #163653;
}

.linkItem>a:hover {
  color: #163653;
}

.mainTextDiv {
  height: 100%;
  width: 40%;
  z-index: 200;
  position: relative;
  margin-left: 15%;
  padding-top: 13%;
}

.BigHeadingWrapper {
  width: 50%;
  height: 50%;
}

.discoverButton {
  margin-bottom: 6.5rem;
}



/* 0 0.5 1 0.5 0 */

/* @keyframes head {
  0% {
    opacity: 0;
    content: "BEST IT SOLUTION AGENCY";
  }
  5% {
    opacity: 0.5;
    content: "BEST IT SOLUTION AGENCY";
  }
  10% {
    opacity: 1;
    content: "BEST IT SOLUTION AGENCY";
  }
  15% {
    opacity: 0.5;
    content: "BEST IT SOLUTION AGENCY";
  }
  20% {
    opacity: 0;
    content: "BEST IT SOLUTION AGENCY";
  }
  25% {
    opacity: 0;
    content: "BEST MARKETING SERVICES ";
  }
  30% {
    opacity: 0.5;
    content: "BEST MARKETING SERVICES ";
  }
  35% {
    opacity: 1;
    content: "BEST MARKETING SERVICES ";
  }
  40% {
    opacity: 0.5;
    content: "BEST MARKETING SERVICES ";
  }
  45% {
    opacity: 0;
    content: "BEST MARKETING SERVICES ";
  }
  50% {
    opacity: 0;
    content: "BEST IT SOLUTION AGENCY";
  }
  55% {
    opacity: 0.5;
    content: "BEST IT SOLUTION AGENCY";
  }
  60% {
    opacity: 1;
    content: "BEST IT SOLUTION AGENCY";
  }
  65% {
    opacity: 0.5;
    content: "BEST IT SOLUTION AGENCY";
  }
  70% {
    opacity: 0;
    content: "BEST IT SOLUTION AGENCY";
  }
  75% {
    opacity: 0;
    content: "BEST MARKETING SERVICES";
  }
  80% {
    opacity: 0.5;
    content: "BEST MARKETING SERVICES";
  }
  85% {
    opacity: 1;
    content: "BEST MARKETING SERVICES";
  }
  90% {
    opacity: 0.5;
    content: "BEST MARKETING SERVICES";
  }
  95% {
    opacity: 0;
    content: "BEST IT SOLUTION AGENCY";
  }
  100% {
    opacity: 0;
    content: "BEST MARKETING SERVICES";
  }
} */

.smallSubHeading {
  margin: 20px 0px;
  font-size: 0.9em;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

@media screen and (max-width: 810px) {
  .mainHomeDiv {
    width: 100%;
    /* height: 100vh; */
    max-height: 100%;
    min-height: 700px;
    background-color: #7abaf7;
    position: relative;
    margin-bottom: 220px;
    margin-bottom: 40%;
  }
  .rightDiv {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-bottom: 10px;
    margin-top: 100px;
  }
  .mainImg {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0px auto;
    width: 100%;
    height: auto;
    max-height: 100%;
    padding: 0px 3rem;
    object-fit: contain;
    z-index: 10;
  }
  .leftDivLinksSec1, .leftDivLinksSec2 {
    display: none;
  }
  .rightBackCircle {
    height: 100%;
    max-width: 100%;
    position: absolute;
    right: -55%;
    object-fit: contain;
    overflow: hidden;
    padding-top: 7rem;
  }
  .leftDiv {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
  }
  .sideCircle {
    position: absolute;
    width: 40%;
    object-fit: contain;
    top: 20%;
  }
  .Substract {
    position: absolute;
    bottom: -16%;
    height: 60%;
    right: 20%;
    object-fit: contain;
    /* background-color: red; */
  }
  .mainTextDiv {
    width: 95%;
    height: 60%;
    padding-top: 18rem;
    z-index: 200;
    position: relative;
    margin-left: 2% !important;
    bottom: 0;
    /* background-color: red; */
    /* overflow: hidden; */
  }
  .BigHeading {
    font-size: 3.5rem;
    font-weight: 900;
    font-family: "Antonio", sans-serif;
    margin: 20px 0px;
    display: block;
    padding: 0px 0px !important;
    line-height: 1;
    font-weight: bolder;
    color: #163653;
    text-overflow: ellipsis;
    text-transform: uppercase;
    text-transform: uppercase;
    margin-left: 5% !important;
  }
  .smallHeading {
    font-size: .8rem;
    font-family: "Antonio", sans-serif;
    margin: 0px 0px;
    display: block;
    padding: 0px 0px !important;
    line-height: 1;
    font-weight: bolder;
    color: #163653;
    text-overflow: ellipsis;
    text-transform: uppercase;
    margin-left: 8px;
    font-weight: 900;
  }
  .midHeading {
    font-weight: 900 !important;
    font-size: 1.8rem;
    font-family: "Antonio", sans-serif;
    margin: 10px 0px;
    display: block;
    padding: 0px 0px !important;
    line-height: 1;
    font-weight: bolder;
    color: #163653;
    text-overflow: ellipsis;
    text-transform: uppercase;
  }
  .clippedImg {
    position: absolute;
    right: 0;
    top: 0;
    height: 35%;
    width: 100%;
    padding-bottom: 10px;
  }
  .BigHeadingWrapper {
    width: 100%;
    height: 100%;
  }
  .BigHeading {
    font-size: 6rem;
    font-family: "Antonio", sans-serif;
    margin: 20px 0px;
    display: block;
    padding: 0px 0px !important;
    line-height: 1;
    font-weight: bolder;
    color: #163653;
    text-overflow: ellipsis;
    text-transform: uppercase;
    text-transform: uppercase;
    margin-bottom: 3rem;
  }
  .smallSubHeading {
    margin: 0px 0px;
    font-size: 0.9rem;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-left: 5% !important;
  }
  .leftDivLinks {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 90px;
    padding-bottom: 30px;
    height: 100%;
    width: 0%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  .discoverButton {
    margin-bottom: 5rem;
  }
}