.mainDiv {
  padding: 0 15% 5% 15%;
}
.mainDiv h1 {
  font-family: "Antonio", sans-serif;
  font-size: 3rem;
  text-transform: uppercase;
  text-align: center;
  color: #163653;
  margin-bottom: 1rem;
}

.box {
  width: 300px;
  height: 300px;
  margin: 20px 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: fill;
  border-radius: 1rem;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
}
.video {
  display: block;
  width: 0;
  height: 0;
  object-fit: cover;
  border-radius: 20rem;
  filter: brightness(100%);
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  cursor: pointer;
  z-index: -1;
}
.title {
  top: 0;
  left: 0;
  font-size: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  font-weight: 700;
  color: black;
  text-transform: uppercase;
  padding: 12px;
  font-family: Arial, sans-serif;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.title {
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
  transition: color 0.2s ease;
  color: white;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: space-evenly;
}
@media screen and (max-width: 810px) {
  .mainDiv {
    padding: 5rem 5%;
  }
  .mainDiv ul li {
    margin: 0.4rem 100%;
  }
}
