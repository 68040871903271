.smallTextData {
  font-family: "Poppins", sans-serif;
  text-align: center;
  /* font-weight: 900; */
  color: #163653;
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
  line-height: 2;
  text-align: justify;
  text-justify: inter-word;
}

.smallTextData > P {
  line-height: 1.3;
}

.textBold{
  color: #0f2942;
  font-weight: 900;
  font-family: Poppins;
  line-height: 2;
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;

}

.pN1{
  margin: 0px !important;
  padding: 0.8rem !important;  
}


.pN2{
  margin: 0px !important;
  padding: 0.4rem !important;  
}


.pN3{
  margin: 0px !important;
  padding: 0.0rem !important;  
  
}

.pTagStyle{
  color: #0f2942;
  font-family: Poppins;
  margin: 0px !important;
  line-height: 2 !important;
  padding-top: 2rem !important;
  padding-bottom: .2rem !important;
}

.listItem{
  width: 100%;
  padding-left: 2rem;
  line-height: 1.2 !important;
}

.pH1{
  font-family: Poppins;
  margin: 0px !important;
  font-size: 1.8rem;
  font-weight: bold;

}


.pH2{
  font-family: Poppins;
  margin: 0px !important;
  font-size: 1.6rem;
  font-weight: bold;
}


.pH3{
  font-family: Poppins;
  margin: 0px !important;
  font-size: 1.4rem;
  font-weight: bold;

}

.pUnderline{
  margin: 0px !important;
  text-decoration: underline;  
  line-height: 2 !important;
}

.pItalic{
  margin: 0px !important;
  font-style: italic;
  line-height: 2 !important;
}



@media screen and (max-width: 810px) {
  .listItem{
    width: 100%;
    padding-left: 1rem;
  }
}