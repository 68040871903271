.mainHomeDiv {
  width: 100%;
  height: 100%;
  background-color: #7abaf7;
  position: relative;
  min-height: 580px;
  /* overflow: hidden; */
}

.rightDiv {
  /* background-color: red; */
  position: absolute;
  right: 0;
  top: 0;
  height: 90%;
  width: 50%;
  overflow: hidden;
  padding-bottom: 10px;
  animation: bannerBlink 8s infinite;
  /* overflow: hidden; */
}

.Substract {
  position: absolute;
  height: 50%;
  /* background-color: red; */
  z-index: 12;
  right: 190px;
  bottom: -15px;
}

.mainImg {
  /* background-color: red; */
  position: absolute;
  top: -3.5rem;
  width: 90%;
  right: -15%;
  height: 105%;
  z-index: 10;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  /* border-radius: 50%; */
  overflow: hidden;
  object-fit: cover;
}

@keyframes bannerBlink {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.rightBackCircle {
  height: 100%;
  width: 100%;
  object-fit: contain;
  position: absolute;
  right: -3rem;
  top: -14%;
  overflow: hidden;
}

.leftDiv {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30%;
  height: 50%;
  z-index: 0;
  overflow: hidden;
}

.sideCircle {
  position: absolute;
  width: 100%;
}

.leftDivLinks {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 140px;
  padding-bottom: 30px;
  height: 100%;
  width: 6%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.leftDivLinksSec1 {
  transform: rotate(270deg);
  display: flex;
  list-style: none;
  justify-content: space-around;
}

.leftDivLinksSec2 {
  transform: rotate(0deg);
  display: flex;
  list-style: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 45%;
}

.leftDivLinksSec2 .linkItem {
  padding: 10px 10px;
  display: flex;
  margin: 15px 0px;
  font-family: "Antonio", sans-serif;
  font-size: 1.1em;
  font-weight: 900;
  color: #163653;
  display: flex;
  justify-content: center;
  padding-right: 20px;
}

.leftDivLinksSec2 .linkItem > a > i {
  font-size: 1.4rem;
}

.leftDivLinksSec2 .linkItem > a:hover {
  color: #163653;
}

.linkItem {
  padding: 10px 10px;
  height: 10px;
  display: flex;
  font-size: 1em;
  min-width: 80px;
  font-family: "Antonio", sans-serif;
  font-size: 1.1em;
  font-weight: 900;
  color: #163653;
}
.linkItem a {
  color: #163653;
}
.linkItem > a:hover {
  color: #163653;
}

.mainTextDiv {
  height: 100%;
  width: 46%;
  z-index: 200;
  position: relative;
  margin-left: 15%;
  padding-top: 10%;
  padding-bottom: 4%;
  /* overflow: hidden; */
}

.BigHeading {
  font-size: 5.5rem;
  font-family: "Antonio", sans-serif;
  margin: 20px 0px;
  display: block;
  padding: 0px 0px !important;
  line-height: 1;
  font-weight: bolder;
  color: #163653;
  text-overflow: ellipsis;
  text-transform: uppercase;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.smallSubHeading {
  margin: 10px 0px;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
}

.midHeading {
  font-size: 2rem;
  font-family: "Antonio", sans-serif;
  margin: 10px 0px;
  display: block;
  padding: 0px 0px !important;
  font-weight: bolder;
  color: #163653;
  text-transform: uppercase;
  /* margin-bottom: 2rem; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-height: 1;
  max-height: 4;
}

.seHeading {
  font-family: "Antonio", sans-serif;
  display: block;
  line-height: 1;
  font-weight: bolder;
  color: #163653;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin-left: 30%;
  margin-bottom: 2rem;
}

.smallHeading {
  font-size: 1rem;
  font-family: "Antonio", sans-serif;
  margin: 0px 0px;
  display: block;
  padding: 0px 0px !important;
  line-height: 1;
  font-weight: bolder;
  color: #163653;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin-left: 8px;
  margin-bottom: 2rem;
}

.lowOpacity {
  opacity: 0.5 !important;
  text-transform: uppercase;
}

@media screen and (max-width: 810px) {
  .rightDiv {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-bottom: 10px;
    margin-top: 100px;
    animation: none;
  }
  .mainImg {
    position: absolute;
    top: -1rem;
    right: 0;
    margin: 0px auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 0px 3rem;
    object-fit: cover;
    z-index: 10;
    /* background-color: red; */
    border-radius: 50%;
  }
  .leftDivLinksSec1,
  .leftDivLinksSec2 {
    display: none;
  }
  .rightBackCircle {
    height: 100%;
    max-width: 100%;
    position: absolute;
    right: -55%;
    object-fit: contain;
    overflow: hidden;
    padding-top: 7rem;
  }
  .leftDiv {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
  }
  .sideCircle {
    position: absolute;
    width: 40%;
    object-fit: contain;
    top: 20%;
  }
  .Substract {
    position: absolute;
    bottom: -16%;
    height: 60%;
    right: 20%;
    object-fit: contain;
    /* background-color: red; */
  }
  .mainTextDiv {
    width: 80%;
    height: 60%;
    padding-top: 18rem;
    z-index: 200;
    position: relative;
    margin-left: 5% !important;
    bottom: 0;
    /* background-color: red; */
    /* overflow: hidden; */
  }
  .BigHeading {
    font-size: 3.5rem;
    font-weight: 900;
    font-family: "Antonio", sans-serif;
    margin: 20px 0px;
    display: block;
    padding: 0px 0px !important;
    line-height: 1;
    font-weight: bolder;
    color: #163653;
    text-overflow: ellipsis;
    text-transform: uppercase;
    text-transform: uppercase;
  }
  .smallHeading {
    font-size: 0.8rem;
    font-family: "Antonio", sans-serif;
    margin: 0px 0px;
    display: block;
    padding: 0px 0px !important;
    line-height: 1;
    font-weight: bolder;
    color: #163653;
    text-overflow: ellipsis;
    text-transform: uppercase;
    margin-left: 8px;
    font-weight: 900;
  }
  .midHeading {
    font-weight: 900 !important;
    font-size: 1.8rem;
    font-family: "Antonio", sans-serif;
    margin: 10px 0px;
    display: block;
    padding: 0px 0px !important;
    line-height: 1;
    font-weight: bolder;
    color: #163653;
    text-overflow: ellipsis;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-height: 1;
    max-height: 4;
  }
  .clippedImg {
    position: absolute;
    top: 0;
    margin: 0px 10vw;
    height: 55vw;
    width: 80vw;
    padding: 0 20vw;
    padding-bottom: 10px;
    animation: bannerBlink 8s infinite;
    z-index: 100;
  }
}
