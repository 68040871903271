@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

.imgcontainer {
  height: 100%;
  width: 100%;
  /* height: 80%; */
  /* position: relative;*/
  object-fit: cover; 
  /* background-color: green; */
  border-radius: 1rem;
  /* overflow: hidden; */
}

.imgMenu {
  width: 100%;
  height: 100%;
  position: relative;
  object-fit: cover;
  border-radius: 1rem;
}


.bluecircle {
  padding: 1rem;
  color: white;
  width: 2.4rem;
  height: 2.4rem;
  bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 2rem;
  border-radius: 50%;
  font-family: "Poppins", sans-serif;
  position: absolute;
  background: #163653;
}

.projectcontainer {
  /* margin: 1rem; */
  display: inline-block;
  position: relative;
  width: 40%;
  height: 24rem;
  /* background-color: red; */
  min-height: 30vw;
  margin: 20px;
  /* padding: 1rem; */
  text-align: initial !important;
}

.projectcontainer:nth-child() {
  margin-right: auto;
}

/* .projectcontainer:first-child{
  margin-right:auto;
}
.projectcontainer:last-child{
  margin-right:0px;
} */

.bluecontainer {
  width: 60%;
  height: 7.5rem !important;
  display: inline-flex;
  background: #7abaf7;
  justify-content: space-between;
  color: #163653;
  left: -1rem;
  transition: 0.4s;
  bottom: 0;
  padding: 0.8rem 1rem;
  position: absolute;
  border-radius: 0.6rem;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
  
}

.bluecontainer:hover {
  transform: scale(1.05);
}

.whiteContainer {
    width: 60%;
    height: 7.5rem !important;
    display: inline-flex;
    background: #fff;
    justify-content: space-between;
    color: #163653;
    left: -1rem;
    transition: 0.4s;
    bottom: 0;
    padding: 0.8rem 1rem;
    position: absolute;
    border-radius: 0.6rem;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    
  }
  
  .whiteContainer:hover {
    transform: scale(1.05);
  }

.blueheading {
  width: 70%;
  height: 100%;
  float: left;
  font-family: "Antonio", sans-serif;
  text-transform: uppercase;
  padding-right: 2%;
  font-weight: 900;
  font-size: 1.4rem;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

@media screen and (max-width: 810px) {
  .projectcontainer {
    /* margin: 1rem; */
    display: inline-block;
    position: relative;
    width: 100%;
    margin: 10px 0px;
    /* padding: 1rem; */
    text-align: initial !important;
  }
  .bluecontainer {
    width: 75%;
    height: 6.5rem !important;
    display: inline-flex;
    background: #7abaf7;
    justify-content: space-between;
    color: #163653;
    left: .6rem;
    transition: 0.3s;
    bottom: 0.6rem;
    padding: 0.5rem .7rem;
    position: absolute;
    border-radius: 0.6rem;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    
  }
  
  .bluecontainer:hover {
    transform: scale(1.05);
  }
  .whiteContainer {
    width: 75%;
    height: 7.5rem !important;
    display: inline-flex;
    background: #fff;
    justify-content: space-between;
    color: #163653;
    left: .6rem;
    transition: 0.3s;
    bottom: 0.6rem;
    padding: 0.5rem .7rem;
    position: absolute;
    border-radius: 0.6rem;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    
  }
  
  .whiteContainer:hover:hover {
    transform: scale(1.05);
  }
  
}