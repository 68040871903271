@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@600&family=Poppins:ital,wght@0,200;1,200;1,300&display=swap');

@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);



.RootDiv {
  padding: 0 15% !important;
}

.markDownStyle{
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  margin: 8rem 0px;
  display: block;
  padding: 0px 15% !important;
  line-height: 1.8;
  text-align: justify;
  color: #163653;
}

.spacer{
  padding: 1%;
}

@media screen and (max-width: 810px) {
  .RootDiv {
    padding: 0 5% !important;
  }
  .markDownStyle{
    font-size: 1.2rem;
    padding: 0px 5% !important;
  }
}