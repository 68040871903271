@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);
.mainDiv {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 70px 15%;
  padding-bottom: 180px;
  background-color: #163653;
}

.contactInfo {
  width: 50%;
  padding: 30px;
  padding-left: 0px !important;
}

.heading {
  color: #fff;
  font-family: "Antonio", sans-serif;
  font-size: 2.8em;
}

.subHeading {
  color: #aeb6bf;
  margin: 40px 0px;
}

.infoElementDiv {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.infoIcon {
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7abaf7;
  border-radius: 50%;
}

.infoElement {
  margin-left: 20px;
}

.infoHeading {
  color: #fff;
  font-family: "Antonio", sans-serif;
  font-size: 1em;
}

.infoData {
  font-weight: 600;
  font-size: 0.8em;
  color: #aeb6bf;
}

.infoElement>p {
  padding: 0;
  margin: 0;
}

.row {
  background: yellow;
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.contactForm {
  width: 50%;
  min-height: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
}

.inputDiv {
  display: flex;
  padding: 5px;
  width: 100%;
}

.inputTag {
  outline: none;
  border: 0px;
  width: 100%;
  padding: 20px 30px;
  border-radius: 10px;
  margin: 5px;
  font-family: "Poppins", sans-serif;
  /* font-weight: 600; */
  flex: 1;
  color: #3a3939;
  font-size: 1.2rem;
}

.textareaTag {
  outline: none;
  border: 0px;
  font-size: 1.2rem;
  padding: 20px 30px;
  border-radius: 10px;
  margin: 5px;
  font-family: "Poppins", sans-serif;
  flex: 1;
  resize: none;
  height: 150px !important;
}

.sendMsgBtn {
  background-color: #7abaf7;
  width: 50%;
  margin-left: 10px;
  margin-top: 15px;
  padding: 15px;
  text-align: center;
  border-radius: 7px;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  color: #163653;
  position: relative;
  overflow: hidden;
  /* overflow: hidden; */
}

.buttonMargin {
  margin-left: 10px;
  margin-top: 10px;
}

.mapLocation {
  width: 70%;
  height: 350px;
  border-radius: 20px;
  overflow: hidden;
  margin: 0px auto;
  margin-top: -100px;
  margin-bottom: 30px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}

.styleErr {
  outline: none;
  border: 2px solid red;
  width: 100%;
  padding: 20px 30px;
  border-radius: 10px;
  margin: 5px;
  font-family: "Poppins", sans-serif;
  flex: 1;
  color: #3a3939;
  display: inline;
  animation: border-fade 2s forwards;
}

@media screen and (max-width: 810px) {
  .mainDiv {
    flex-direction: column;
    padding: 30px 5%;
    padding-bottom: 10rem;
  }
  .contactInfo {
    width: 100%;
    padding: 0px;
    padding-left: 0px !important;
  }
  .heading {
    text-align: center;
  }
  .contactForm {
    width: 100%;
  }
  .inputDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: -5px 0px;
  }
  
  .inputTag {
    outline: none;
    border: 0px;
    width: 100%;
    padding: 20px 30px;
    border-radius: 6px;
    margin: 3px 0px;
    font-family: "Poppins", sans-serif;
    /* font-weight: 600; */
    flex: 1;
    color: #3a3939;
    font-size: 1.2rem;
  }
  
  .textareaTag {
    outline: none;
    border: 0px;
    font-size: 1.2rem;
    padding: 20px 30px;
    border-radius: 10px;
    margin: 5px 0px;
    font-family: "Poppins", sans-serif;
    flex: 1;
    resize: none;
    min-height: 250px;
  }
  .buttonMargin {
    margin-left: 0px;
    margin-top: 10px;
  }
  .mapLocation {
    width: 100%;
    height: 350px;
    border-radius: 0px;
    overflow: hidden;
    margin: 0px auto;
    margin-top: -100px;
    margin-bottom: 30px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid black;
  }
  
}