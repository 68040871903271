.ProgressBars{

    display: flex;
    flex-direction: column;
    background-color: #163653;
    justify-content: center;
    padding:5% 15%;
    
    }
    .heading{
    
    text-transform: uppercase;
    font-size:2.5em;
    font-weight: 900;
    font-size: 3rem;
    font-family: "Antonio", sans-serif;
    line-height: 2;
    color:#fff;
    display: flex;
    justify-content: center;
    }
    .heading>h1{
    margin-bottom:3rem;
    text-transform: uppercase;
    width: 100%;
    position: relative;
    margin-bottom:50px;
    text-align: center;
    font-weight: 900;
    font-family: Antonio;
    color:white;
    font-size: 3rem;
    }
    .barContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
    .bar{
    display: flex;
    flex-direction: row;
    padding:3.5rem 0px;
    align-items: center;
    width: 50%;
    font-family: Antonio;
    font-weight: 900;
    }
    .bar>h2{
    margin:3rem;
    color:#fff;
    font-size:1.4rem;
    text-transform: uppercase;
    }