.mainDiv {
    padding: 3rem 15%;
    background-color: #163653;
    color: #fff;
    display: flex;
    align-items: center;
}

.content p {
    line-height: 1;
}

.content {
    width: 60%;
    height: 100%;
    padding: 20px 0rem;
    padding-right: 3rem;
}

.emailField {
    width: 40%;
    height: 100%;
    padding: 20px 0rem;
    padding-left: 3rem;
}

.heading {
    font-family: "Antonio", sans-serif;
    text-transform: uppercase;
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.subHeading {
    font-family: "Poppins", sans-serif;
}

.emailInput {
    background-color: #fff;
    padding: 3px;
    height: 3rem;
    border-radius: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
}

.emailInputField{
    margin-left: 13px;
    outline: none;
    border: 0px;
    font-family: "Poppins", sans-serif;
    height: 100%;
    padding: 5px 20px;
    width: 85%;

}
.submitDiv{
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    background-color: #163653;
    border-radius: 50%;
    font-size: .7rem;
    cursor: pointer;
}

.submitMsg{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
}

.contentDiv{
    width: 80vw;
    height: 60vh;
    background-color: #fff;
    border-radius: 20px;
    padding: 3rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.crossIcon{
    position: absolute;
    right: 2.5rem;
    top: 2rem;
    font-size: 2rem;
    color: #7abaf7;
    cursor: pointer;
}

.msgHeading{
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "Antonio", sans-serif;
    color: #163653;
}
.msgTxt{
    font-size: 1.2rem;
    font-family: "Poppins", sans-serif;
    color: #163653;
}

.hidden{
    display: none;
    transition: .2s;
}
.errMsg{
    width: 100%;
    margin-top: 5px;
    text-align: center;
    color: #7abaf7;
    font-family: "Poppins", sans-serif;

}


@media screen and (max-width: 810px) {
    .mainDiv {
        flex-direction: column;
        padding: 3rem 5%;
    }
    .content {
        width: 100%;
        height: 100%;
        padding: 20px 0rem;
        padding-right: 0rem;
        text-align: center;
    }
    .emailField {
        width: 100%;
        padding-left: 0rem;
    }
}