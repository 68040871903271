.cardContainer{
    /* background-color: pink; */
    display: flex;
    flex-direction: row;
}
.card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}
.light{
    color: #163553b5;
}
.h3{
    font-size: 1.5rem;
    font-family: "Antonio", sans-serif;
   
    display: block;
    padding: 0px 0px !important;
    line-height: 1;
    font-weight: bolder;
    color: #163653;
    text-overflow: ellipsis;
    text-transform: uppercase;
    /* background-color:rgb(208, 115, 131); */
}
.h2{
    width: 100%;
    position: relative;
    margin: 50px 0px;
    text-align: center;
    font-weight: 900;
    font-family: Antonio;
    color:#163653;
    font-size: 3rem;
    /* background-color:rgb(208, 115, 131); */
}
.projectContainer1{
    /* background-color: pink; */
    padding-top: 5rem;
   display:flex;
   justify-content: space-between;
}

.project{
    margin-left:1rem;
    background-color:blueviolet
}
.career2{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5rem;
}

.offering{
    background-color: #fff;
    color:#163653;
    /* height: 100vh; */
    width: 100%;
    font-size: 1.5rem;
    margin-top:8vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10vh 15%;
  
  }
  .offering h1{
    width: 100%;
    position: relative;
    margin-bottom:50px;
    text-align: center;
    font-weight: 900;
    font-family: Antonio;
    color:#163653;
    font-size: 3em;
  
  }
  .offercard{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }