.container {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
a {
  text-decoration: none;
  color: #7abaf7;
}

.main {
  padding: 0 15%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  width: 100%;
  height: 100px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin-top: 3rem;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  width: 45%;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

/*  Section 2 OUT BEST BROJECTS */

.Projects {
  background-color: #fff;
  color: #163653;
  width: 100%;
  font-size: 1.5rem;
  padding: 0 15%;
  padding-bottom: 10vh;
  padding-top: 10vh;
}
.Projects h1 {
  margin-bottom: 50px;
  text-align: center;
  font-weight: 900;
  font-family: Antonio;
  color: #163653;
  text-align: center;
  font-size: 3rem;
}
.ProjectsComponents {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.ProjectsComponents:nth-child() {
  margin-right: auto;
}
.ProjectsComponents:first-child {
  margin-right: auto;
}

/*  What are we offering Section */

.offering {
  background-color: #fff;
  color: #163653;
  /* height: 100vh; */
  width: 100%;
  font-size: 1.5rem;
  margin-top: 8vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10vh 15%;
}
.offering h1 {
  width: 100%;
  position: relative;
  text-align: center;
  font-weight: 900;
  font-family: Antonio;
  color: #163653;
  font-size: 3rem;
}
.offercard {
  padding: 1rem 15%;
  margin-bottom: 4rem;
}
.carouselStyle {
  z-index: 0 !important;
  padding: 1rem 0.2rem;
}
.offercard::-webkit-scrollbar {
  width: 1rem;
  height: 0.7rem;
}

/* Track */
.offercard::-webkit-scrollbar-track {
  display: none;
}

/* Handle */
.offercard::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #163653;
}

@media screen and (max-width: 810px) {
  .Projects {
    background-color: #fff;
    color: #163653;
    width: 100%;
    font-size: 1.5rem;
    padding: 0 5%;
    padding-bottom: 10vh;
    padding-top: 10vh;
  }
  .ProjectsComponents {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .ProjectsComponents:nth-child() {
    margin-right: 0;
  }
  .ProjectsComponents:first-child {
    margin-right: 0;
  }
  .offering {
    background-color: #fff;
    color: #163653;
    /* height: 100vh; */
    width: 100%;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0vh 15%;
  }
  .offercard {
    padding: 1rem 5%;
    margin-bottom: 4rem;
  }
  .offering h1 {
    font-size: 2rem;
  }
  .Projects h1 {
    font-size: 2rem;
    margin-top: 2rem;
  }
}
