@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

.recognitionDiv {
  display: flex;
  flex-direction: row;
  background-color: #163653;
  justify-content: space-between;
  /* margin:.05rem; */
  padding: 5% 15%;
  align-items: center;
}
.recognition {
  border-bottom: 2px solid rgb(46, 73, 89);
}
.recognitionDiv1 {
  width: 70%;
  color: #fff;
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
}
.recognitionDiv2 {
  display: flex;
  flex-direction: row;

  align-items: center;
  /* background-color: red; */
  /* padding:1.5rem; */
}
.recognitionDiv1 > h2 {
  text-transform: uppercase;
  font-size: 2.5em;
  font-weight: 900;
  font-size: 3rem;
  font-family: "Antonio", sans-serif;
  line-height: 1;
  margin-bottom: 2rem;
}
.recognitionDiv1 > p {
  font-size: 0.8em;
  line-height: 2;
  padding-top: 0;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 1.5;
  max-height: 3;
}

@media only screen and (max-width: 810px) {
  .recognitionDiv {
    display: flex;
    flex-direction: column;
    background-color: #163653;
    justify-content: center;
    /* margin:.05rem; */
    padding: 5% 5%;
    align-items: center;
  }
  .recognitionDiv1 {
    width: 100%;
    text-align: center;
    color: #fff;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: column;
  }
  .recognitionDiv2 {
    display: flex;
    flex-direction: row;

    align-items: center;
    /* background-color: red; */
    /* padding:1.5rem; */
  }
}
