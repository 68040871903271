.mainDiv {
  padding: 5% 10%;
}

.heading {
  font-family: "Antonio", sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 3rem;
  font-weight: normal;
  color: #163653;
  margin-bottom: 1rem;
}
.title {
  font-family: "Antonio", sans-serif;
  text-transform: uppercase;
  text-align: center;
  color: #163653;
  font-size: xx-large;
  font-weight: 100;
  margin-bottom: 1rem;
}

.CarouselWraper {
  position: relative;
  margin-bottom: 40px;
}
.Carousel {
  display: flex;
}
.CarouselItem {
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  height: 40vw;
  width: 40vw;
  max-width: 400px;
  max-height: 400px;
  justify-content: center;
  position: relative;
}
.CarouselItemWrapper {
  display: flex;
  padding: 2vw;
  padding-bottom: 60px;
  justify-content: center;
  object-fit: scale-down;
}
.CarouselItemHover {
  width: 70%;
  height: 100%;
  position: absolute;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.7s ease-in;
  transform: translateX(-101%);
}
.CarouselItem:hover > .CarouselItemHover {
  transform: translateX(0%);
}

.textData {
  font-size: x-large;
  text-align: center;
}

@media only screen and (max-width: 810px) {
}
