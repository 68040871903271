.buttonContainer {
    margin-top: 8rem;
    margin-bottom: 8rem;
    display: flex;
    justify-content: center;
}

.hidden {
    display: none !important;
}

